// Nucleo icons

.section-nucleo-icons .icons-container {
  position: relative;
  max-width: 100%;
  height: 360px;
  margin: 0 auto;
  z-index: 1;
}

.section-nucleo-icons {
  --icon-size: 5rem;
  --icon-sm-size: 3.75rem;
  --gutter: 7rem;

  .icons-container {
    i {
      position: absolute;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: $white;
      z-index: 1;
      transform: translate(-50%, -50%);
      @include box-shadow($box-shadow);
      transition: all 0.2s cubic-bezier(0.25, 0.65, 0.9, 0.75);

      &.icon {
        width: var(--icon-size);
        height: var(--icon-size);
        font-size: 1.7em;
      }

      &.icon-sm {
        width: var(--icon-sm-size);
        height: var(--icon-sm-size);
        font-size: 1.5em;
      }

      &:nth-child(1) {
        font-size: 42px;
        color: theme-color("warning");
        z-index: 2;
      }
    }

    &:not(.on-screen) {
      i {
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;

        &:not(:nth-child(1)) {
          opacity: 0;
        }
      }
    }

    &.on-screen {
      i {
        opacity: 1;

        &:nth-child(1) {
          left: 50%;
          top: 50%;
          font-size: 42px;
          color: theme-color("warning");
        }

        &:nth-child(2) {
          left: calc(50% + (var(--gutter) * 1.7));
          top: 50%;
        }

        &:nth-child(3) {
          left: calc(50% + var(--gutter));
          top: calc(50% + var(--gutter));
        }

        &:nth-child(4) {
          left: calc(50% + var(--gutter));
          top: calc(50% - var(--gutter));
        }

        &:nth-child(5) {
          left: calc(50% + (var(--gutter) * 4));
          top: 50%;
        }

        &:nth-child(6) {
          left: calc(50% + (var(--gutter) * 2.7));
          top: calc(50% + (var(--gutter) * 1.5));
        }

        &:nth-child(7) {
          left: calc(50% + (var(--gutter) * 2.7));
          top: calc(50% - (var(--gutter) * 1.5));
        }

        &:nth-child(8) {
          left: calc(50% - (var(--gutter) * 1.7));
          top: 50%;
        }

        &:nth-child(9) {
          left: calc(50% - var(--gutter));
          top: calc(50% + var(--gutter));
        }

        &:nth-child(10) {
          left: calc(50% - var(--gutter));
          top: calc(50% - var(--gutter));
        }

        &:nth-child(11) {
          left: calc(50% - (var(--gutter) * 4));
          top: 50%;
        }

        &:nth-child(12) {
          left: calc(50% - (var(--gutter) * 2.7));
          top: calc(50% + (var(--gutter) * 1.5));
        }

        &:nth-child(13) {
          left: calc(50% - (var(--gutter) * 2.7));
          top: calc(50% - (var(--gutter) * 1.5));
        }
      }
    }
  }
}

// Blurable sections

.blur--hover {
  position: relative;

  .blur-item {
    transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
    will-change: transform;
    filter: blur(0);
    opacity: 1;
  }

  .blur-hidden {
    position: absolute;
    top: calc(50% + 7px);
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: $transition-base;
    z-index: 100;
  }

  &:hover {
    .blur-item {
      opacity: 0.8;
      filter: blur(10px);
      transform: scale(0.95);
      z-index: 1;
    }

    .blur-hidden {
      opacity: 1;
      top: 50%;
    }
  }
}


.c-token span:not(.c-remove) {
  margin: -5px;
}

.c-remove svg {
  margin-left: 4px;
  fill: $primary !important;
}

.mat-tooltip {
  white-space: pre-line;
}

.thumb-img-128 {
  object-fit: cover;
  overflow: hidden;
  height: 128px;
  width: 128px;
}

.thumb-img-128-selected {
  object-fit: cover;
  overflow: hidden;
  height: 128px;
  width: 128px;
  background-color: $info;
}

.ng-select .ng-select-container {
  border-radius: 8px;
  border-color: rgba(29, 37, 59, 0.5);
}

datatable-scroller {
  width: 100% !important;
}


.form-control-low {
  padding-left: 5px !important;
  height: 32px;
}

.highcharts-container {
  width: 100% !important;
}

.highcharts-root {
  width: 100% !important;
}

.chart-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}

.chef_os {
  font-size: 10px;
  color: white;
  text-transform: none;
}

.black {
  background: black !important;
}

.blacker {
  background: #282828 !important;
}

.grayish {
  background: #e1e2e7 !important;
}

.grayer {
  background: #f7f8fc !important;
}

.redder {
  background: #2d2727 !important;
}

.state-text {
  font-size: 14px;
  line-height: 1;
  text-decoration: unset;
}

.small-text {
  font-size: 10px;
  line-height: 1;
  text-decoration: unset;
}

.smaller-text {
  font-size: 8px;
  line-height: 1;
  font-weight: bold;
  text-decoration: unset;
}

.smallest-text {
  font-size: 6px;
  line-height: 1;
  font-weight: bold;
  text-decoration: unset;
}

.med-text {
  font-size: 11px;
  line-height: 1;
  font-weight: bold
}

.large-text {
  font-size: 13px;
  line-height: 1;
  font-weight: bold;
}

.error-bg {
  background: red;
  color: white;
}

.warning-bg {
  background: orange;
}

.green-bg {
  background: forestgreen;
  color: white;
}

.accent-bg {
  background: #0089ab;
  color: white;
}

.green-color {
  color: darkgreen;
}

.error-color {
  color: red;
}

.badge-error {
  @include badge-variant(darken($error, 10%));
}

.dropdown-item {
  cursor: pointer;
}

//
//.cell-actions {
//  overflow-x: visible !important;
//  overflow-y: visible !important;
//}
//
//.datatable-body-cell-label {
//  white-space: nowrap;
//  text-overflow: ellipsis;
//  overflow: hidden;
//}
//
.cell-actions {
  overflow-y: visible !important;
  overflow-x: visible !important;

  .datatable-body-cell-label {
    white-space: unset;
    text-overflow: unset;
    overflow: visible;
  }
}

//.dropdown-menu {
//  box-shadow: unset !important;
//}

input.ng-pristine, input.ng-touched, input.ng-valid {
  height: 32px !important;
}

select.ng-pristine, select.ng-touched, select.ng-valid {
  height: 32px !important;
}

angular2-multiselect.ng-pristine, angular2-multiselect.ng-touched, angular2-multiselect.ng-valid {
  height: 32px;
}

tag-input {
  height: 32px !important;
}

tag {
  height: 24px !important;
}

delete-icon {
  margin-top: -4px;
}

.tag-wrapper {
  line-height: 24px !important;
}

.cuppa-dropdown {
  margin-top: -4px
}

.select-40 {
  height: 40px !important;

  .cuppa-dropdown {
    margin-top: 0;
  }
}

.c-btn.disabled {
  background: #e3e3e3 !important;
}

.cuppa-dropdown .selected-list .c-btn.disabled {
  margin: unset !important;
  margin-bottom: 0 !important;
  margin-top: 4px !important;
  border-radius: 6px;
}

.auto-grow {
  resize: none;
  overflow: hidden;
  min-height: 50px;
  max-height: 100px;
}


.card-dark {
  background: #111111 !important;
}

ngx-datatable.ngx-datatable.bootstrap .datatable-footer .page-count {
  color: #252525 !important;
}

.ng-pristine {
  height: unset !important;
}

tag-input-form {
  width: 100%;
}

.col-1-8 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-1-5 {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-5-8 {
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.col-3-8 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
