/*           badges             */
.badge {
  text-transform: uppercase;
  line-height: 12px;
  border: none;
  text-decoration: none;
  margin-bottom: 5px;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.badge-icon {
  padding: 0.4em 0.55em;

  i {
    font-size: 0.8em;
  }
}

.badge-success {
  @include badge-variant(darken($success, 10%));
}

.badge-orange {
  @include badge-variant(darken(orange, 10%));
}

.badge-cyan {
  @include badge-variant(darken(cyan, 10%));
}

.badge-yellow {
  @include badge-variant(darken(yellow, 10%));
}

.badge-magenta {
  @include badge-variant(darken(magenta, 10%));
}

.badge-green {
  @include badge-variant(darken(green, 10%));
}
